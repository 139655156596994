// import {
//   // MapContainer,
//   // TileLayer,
//   // Marker,
//   // useMapEvents,
//   // Popup
// } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
import {
  GoogleMap,
  Marker, 
  useLoadScript,
  Circle,
} from "@react-google-maps/api";
import { useState, useEffect } from "react";
// import markerIconPng from '/marker-icon-2x.png';
// import { Icon } from "leaflet";
import '../App/App.css';
import axios from "axios";
import { styled } from '@mui/material/styles';
// import { Circle } from 'react-leaflet/Circle';
import "../awayGames/RenderMap.css";
import 'react-day-picker/dist/style.css';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Fade, Paper, Slider, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TripPlannerHeader } from "./TripPlannerHeader.js";
import { LeagueButtons } from "./LeagueButtons.js";
import { FindButton } from "./FindButton.js";
import { ListButton } from "./ListButton.js";
import { Alert } from "./Alert.js";
import { TeamMarker } from "./TeamMarker.js";
import { GameList } from "./GameList.js";
import GameInfoListForm from "./GameInfoListForm.js";

const PrettoSlider = styled(Slider)({
  color: '#425b62',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#425b62',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 35,
    height: 35,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#425b62',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

let defaultLocation = localStorage.getItem("center") !== null ? JSON.parse(localStorage.getItem("center")) : { lat: 40.730610, lng: -73.935242 };
let initialRender = true;
let dateFromPrev = '';
let dateToPrev = '';

const MapContainerComponent = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const [selectedPosition, setSelectedPosition] = useState();
  const [location, setLocation] = useState([]);
  const [gamesLocation, setGamesLocation] = useState();
  const [miles, setMiles] = useState(100);
  const [radius, setRadius] = useState(160934);
  const [lat, setLat] = useState(defaultLocation.lat || 40.730610);
  const [lng, setLng] = useState(defaultLocation.lng || -73.935242);
  const [range, setRange] = useState();
  const [stateListDrawer, setStateListDrawer] = useState({top: false});
  const [closeButtonLocation, setCloseButtonLocation] = useState();
  const [filteredMap, setFilteredMap] = useState();
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    Transition: Fade
  });
  const [snackbarGamesFoundState, setSnackbarGamesFoundState] = useState({
    open: false,
    Transition: Fade
  });

  let baseUrl = global.config.address;

  const setDefaultCenter = (lat, lng) => {
    defaultLocation = {lat, lng};
  };

  useEffect(() => {
    let dateFrom = new Date();
    dateFrom.setMilliseconds(0);
    let dateTo = new Date();
    dateTo.setDate(dateFrom.getDate() + 5);
    dateTo.setMilliseconds(0);

    dateFromPrev = dateFrom;
    dateToPrev = dateTo;

    setRange({from: dateFrom, to: dateTo});
  }, []);

  useEffect(() => {
    let map = new Map();
    location.forEach((game) => {
      let tempGames = map.get(game.arenaName);
      if (tempGames) {
        tempGames.push(game);
        map.set(game.arenaName, tempGames);
      }
      else {
        let listGames = [];
        listGames.push(game);
        map.set(game.arenaName, listGames)
      }
    });
    setFilteredMap(map);
  }, [location]);

  useEffect(() => {
    setSelectedPosition([40.505, -100.09]);
  }, []);

  useEffect(() => {
    if (location.length === 0 && gamesLocation === undefined && initialRender) {
     initialGamesFind();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      open: false
    });
    setSnackbarGamesFoundState({
      ...snackbarGamesFoundState,
      open: false
    });
  };

  const findGames = (Transition) => {
    setLocation([]);
    let games = [];
    var leagueButtons = JSON.parse(localStorage.getItem("league-buttons"));

    let body = {
      dateFrom: range?.from,
      dateTo: range?.to,
      miles: miles,
      lat: lat,
      lng: lng
    }

    const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
    try {
      Promise.all(
        Object.keys(leagueButtons)
          .filter(league => leagueButtons[league] === true)
          .map(async (item) => (await axios.post(baseUrl + `/` + item.toLowerCase() + `TripPlannerGames/`, body)).data)
      )
      .then(function(results) {
        results.forEach(element => {
          games = concat(games, element);
        });

        setLocation( curr => [...curr, ...games] );

        if (games.length === 0) {
          setSnackbarGamesFoundState({
            open: true,
            Transition
          });
        }
      })
    }
    catch (err) {
      console.log(err);
    }
  };

  const initialGamesFind = () => {
    if (range === undefined && location.length === 0 && gamesLocation === undefined)
      return;

    findGames(Fade);
    initialRender = false;
  };

  const handleClick = (Transition) => {
    if (range === undefined) {
      setSnackbarState({
        open: true,
        Transition
      });
      return;
    }
    setGamesLocation(undefined);
    findGames(Transition);
  };

  const updateMiles = (event) => {
    setMiles(event.target.value);
    setRadius(event.target.value * 1609.34)
  };

  const toggleListDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }

    setStateListDrawer({ ...stateListDrawer, [anchor]: open });
    setCloseButtonLocation(location.length > 5 ? 'sticky' : 'relative');
    setGamesLocation();
  };

  function onMapClick(e) {
    setSelectedPosition([e.latLng.lat(), e.latLng.lng()]);
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
    localStorage.setItem("center", JSON.stringify({lat: e.latLng.lat(), lng: e.latLng.lng()}));

    return selectedPosition ? (
      <Marker
        key={selectedPosition[0]}
        position={{lat: e.latLng.lat(), lng: e.latLng.lng()}}
        interactive={false}
        // icon={{url: '/marker-icon-2x.png'}}
      />
    ) : null;
  }

  // const SwipeableDrawerStyled = styled(SwipeableDrawer)({
  //   "& .MuiDrawer-paper": {
  //     overflowY: "visible",
  //     backgroundColor: '#425b62'
  //   },
  // });

  const gamesArray = () => {
    if (filteredMap === undefined) return null;

    let res = [...filteredMap.keys()].map((game) => {
      let temp = filteredMap.get(game);
      let sameTeam = true;
      let locTwo = '';
      temp.forEach(gameIndex => {
        if (gameIndex.home_team_full !== temp.at(0).home_team_full)
          sameTeam = false;
          locTwo = gameIndex;
      });

      let loc = temp[0];
      // if num teams at location is 2, show both icons?
      return (
        <TeamMarker loc={loc} locTwo={locTwo} sameTeam={sameTeam}
          temp={temp} setStateListDrawer={setStateListDrawer} setCloseButtonLocation={setCloseButtonLocation}
          setGamesLocation={setGamesLocation} location={location} stateListDrawer={stateListDrawer}
          setLat={setLat} setLng={setLng}
        />
      );
    });

    return res;
  };

  const circleFollowsCenterMarker = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setLat(lat);
    setLng(lng);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
    <Paper id="tripPlannerPaper">
      <TripPlannerHeader setDefaultCenter={setDefaultCenter} setRange={setRange} range={range} setSelectedPosition={setSelectedPosition} setLat={setLat} setLng={setLng} />
      <LeagueButtons />
      <div className='tripPlannerItems'>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerClassName={"map-container"}
            center={defaultLocation}
            zoom={7}
            onClick={(e) => onMapClick(e)}
            options={{streetViewControl: false, zoomControl: false, fullscreenControl: false, mapTypeControl: false}}
          >
            <PrettoSlider
              sx={{
                top: '50px',
                width: '50%'
              }}
              valueLabelDisplay="on"
              valueLabelFormat={`${miles} mi`}
              aria-label="pretto slider"
              defaultValue={100}
              max={500}
              onChange={updateMiles}
            />
            <Marker position={{ lat, lng }}
              draggable={true}
              onDrag={(e) => circleFollowsCenterMarker(e)}
              // icon={iconPin}
            />
            <Circle center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
              radius={radius} options={{ fillColor: 'blue', strokeColor: 'blue' }}
            />
            {gamesArray()}
            {location.length !== 0 ?
            (
              <div>
                <FindButton gamesFound={true} handleClick={handleClick} />
                <ListButton toggleListDrawer={toggleListDrawer} />
              </div>
            ) : 
            (
              <FindButton gamesFound={false} handleClick={handleClick} />
            )}
          </GoogleMap>
        )}
        <GameList stateListDrawer={stateListDrawer} toggleListDrawer={toggleListDrawer} location={location}
          gamesLocation={gamesLocation} range={range} closeButtonLocation={closeButtonLocation} />
      </div>
      <Alert snackbarState={snackbarState} handleClose={handleClose} message={'Select Date(s)'}/>
      <Alert snackbarState={snackbarGamesFoundState} handleClose={handleClose} message={'No games found.'}/>
    </Paper>
    <Paper
      style={{ justifyContent: 'center', width: '30%', overflowY: 'auto', overflowX: 'hidden', marginLeft: '3px', backgroundColor: '#425b62' }}
    >
      {/* <div style={{ width: '30%', marginLeft: '2px' }}> */}
      <div>
        <div
          style={{ 
            textAlign: 'center',
            width: '100%',
            backgroundColor: '#6a8483'
          }}
        >
          <a
            href="https://vivid-seats.pxf.io/c/5600751/1983156/12730"
            target="_top"
            id="1983156"
          >
            <img
              className="vivid-seats-ticket-link"
              src="//a.impactradius-go.com/display-ad/12730-1983156"
              border="0" alt="" width="728" height="90"
            />
          </a>
          <img alt="" height="0" width="0" src="https://imp.pxf.io/i/5600751/1983156/12730" style={{position: 'absolute', visibility: 'hidden'}} border="0" />
        </div>
        <div style={{ display: 'flex' }}>
          <Button onClick={() => setGamesLocation()}>
            <div
              style={{ display: gamesLocation !== undefined ? 'flex' : 'none',
                color: 'white',
                flexWrap: 'nowrap',
                flexDirection: 'row'
              }}
            >
              <ArrowBackIcon sx={{ color: 'white' } }/>
              <div style={{ paddingLeft: '5px', fontSize: 'small', marginTop: '2px' }}>
                Show All Games
              </div>
            </div>
          </Button>
        </div>
        {
        ((dateFromPrev !== '' || dateToPrev !== '') || range !== undefined || (gamesLocation !== undefined || location.length !== 0)) ?
          <GameInfoListForm range={range ? range : {from: dateFromPrev, to: dateToPrev}} games={gamesLocation ? gamesLocation : location} />
          : null
        }
      </div>
    </Paper>
    </div>
  );
};

export default MapContainerComponent;
