import { Snackbar, SnackbarContent } from "@mui/material";


export const Alert = ({ snackbarState, handleClose, message }) => {

  if (snackbarState === undefined)
    return null;

  return (
    <Snackbar
      open={snackbarState.open}
      style={{paddingBottom: '20%', left: '35%'}}
      TransitionComponent={snackbarState.Transition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={handleClose}
      key={snackbarState.Transition.name}
      message={message}
    >
      <SnackbarContent
        sx={{ backgroundColor: '#61dafb', justifyContent: 'center'}}
        message={message}
      />
    </Snackbar>
  );
}