import { Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Fade from "@mui/material/Fade";

export const FindButton = ({ gamesFound, handleClick }) => {


  return (
    <Button variant="contained" onClick={() => handleClick(Fade)}
      className='find-button'
      sx={{
        backgroundColor: '#61dafb',
        bottom: '5%',
        position: 'absolute',
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        right: gamesFound ? '51%' : null,
        minWidth: 'min-content',
        color: 'white'
      }}
    >
      <SearchIcon sx={{ color: 'white' }} /> Find
    </Button>
  );
}