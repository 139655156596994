import { Button, styled } from '@mui/material';
import MuiGrid from '@mui/material/Grid';
import { useLocalStorage } from "../../Hooks/useLocalStorage";

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

export const LeagueButtons = () => {

  const [leagueButtons, setLeagueButtons] = useLocalStorage("league-buttons", 
  {
    NFL: true,
    NBA: true,
    NHL: true,
    MLB: true,
    CFB: true,
    NCAAB: true
  });

  const leagueButtonSelected = {
    border: 'solid', borderRadius: '20%', color: '#61dafb', 
    borderColor: '#3babca', borderWidth: '2px', height: '30px'
  }

  const leagueButtonDeselected = {
    border: 'solid', borderRadius: '20%', 
    borderColor: '#3babca', borderWidth: '2px', height: '30px'
  }

  return (
    <Grid container style={{ display:'flex', justifyContent: 'center', flexWrap: 'nowrap', padding: '2px 0' }} >
      <Button
        variant={leagueButtons.NFL === true ? "contained" : null}
        sx={ leagueButtons.NFL === true ? leagueButtonSelected : leagueButtonDeselected}
        onClick={() => setLeagueButtons(prev => ({...prev, NFL: !prev.NFL}))}
      >
        <div style={{ color: 'white' }}>
          NFL
        </div>
      </Button>
      <Button
        variant={leagueButtons.NBA === true ? "contained" : null}
        sx={ leagueButtons.NBA === true ? leagueButtonSelected : leagueButtonDeselected}
        onClick={() => setLeagueButtons(prev => ({...prev, NBA: !prev.NBA}))}
      >
        <div style={{ color: 'white' }}>
          NBA
        </div>
      </Button>
      <Button
        variant={leagueButtons.NHL === true ? "contained" : null}
        sx={ leagueButtons.NHL === true ? leagueButtonSelected : leagueButtonDeselected}
        onClick={() => setLeagueButtons(prev => ({...prev, NHL: !prev.NHL}))}
      >
        <div style={{ color: 'white' }}>
          NHL
        </div>
      </Button>
      <Button
        variant={leagueButtons.MLB === true ? "contained" : null}
        sx={ leagueButtons.MLB === true ? leagueButtonSelected : leagueButtonDeselected}
        onClick={() => setLeagueButtons(prev => ({...prev, MLB: !prev.MLB}))}
      >
        <div style={{ color: 'white' }}>
          MLB
        </div>
      </Button>
      <Button
        variant={leagueButtons.CFB === true ? "contained" : null}
        sx={ leagueButtons.CFB === true ? leagueButtonSelected : leagueButtonDeselected}
        onClick={() => setLeagueButtons(prev => ({...prev, CFB: !prev.CFB}))}
      >
        <div style={{ color: 'white' }}>
          CFB
        </div>
      </Button>
      <Button
        variant={leagueButtons.NCAAB === true ? "contained" : null}
        sx={ leagueButtons.NCAAB === true ? leagueButtonSelected : leagueButtonDeselected}
        onClick={() => setLeagueButtons(prev => ({...prev, NCAAB: !prev.NCAAB}))}
      >
        <div style={{ color: 'white' }}>
          NCAAB
        </div>
      </Button>
    </Grid>
  );
}
