import {
  Marker
} from "@react-google-maps/api";


export const TeamMarker = ({ loc, locTwo, sameTeam, temp, setStateListDrawer, 
    setCloseButtonLocation, setGamesLocation, location, stateListDrawer, setLat, setLng }) => {

  const leagueMap = new Map();
  leagueMap.set("NHL", "hockeyIcon.png"); leagueMap.set("MLB", "baseball-dark.png");
  leagueMap.set("NBA", "basketball-dark.png"); leagueMap.set("NFL", "football-dark.png");
  leagueMap.set("NCAAB", "basketball-orange.png"); leagueMap.set("CFB", "football-brown.png");

  const toggleListDrawerTwo = (anchor, open) => {

    setStateListDrawer({ ...stateListDrawer, [anchor]: open });
    setCloseButtonLocation(location.length > 5 ? 'sticky' : 'relative');
  };

  return (
    <div>
      <Marker
        key={loc.home_team_id + loc.gameday + loc.away_team_id}
        position={{ lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitude + .001) }}
        icon={{
          url: leagueMap.get(loc.league),
          scaledSize: { width: 30, height: 30 },
        }}
        interactive={true}
        onClick={() => {
          toggleListDrawerTwo('top', true);
          // setActiveMarker(loc);
          // setGamesLocation(sameTeam ? loc : temp);
          setGamesLocation(sameTeam ? temp : loc);
          // setLat(parseFloat(loc.latitude));
          // setLng(parseFloat(loc.longitude + .001));
        }}
      >
      </Marker>
      {!sameTeam ? 
      (
        <Marker
          key={locTwo.home_team_id + locTwo.gameday + locTwo.away_team_id}
          position={{ lat: parseFloat(locTwo.latitude), lng: parseFloat(locTwo.longitude - .001) }}
          icon={{
            url: leagueMap.get(locTwo.league),
            scaledSize: { width: 30, height: 30 },
          }}
          interactive={true}
          onClick={() => {
            toggleListDrawerTwo('top', true);
            // setActiveMarker(loc);
            setGamesLocation(sameTeam ? temp : locTwo);
            // setLat({lat: parseFloat(loc.latitude)});
            // setLng({lng: parseFloat(loc.longitude + .001)});
          }}
        >
        </Marker>
      ) : null}
    </div>
  );
}