import React, {useState, useEffect, useCallback} from 'react';
import MuiGrid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

const GameInfoListForm = ({ single = false, range, games }) => {

  const dateMap = new Map();
  dateMap.set(0, "Sunday"); dateMap.set(1, "Monday"); dateMap.set(2, "Tuesday"); dateMap.set(3, "Wednesday");
  dateMap.set(4, "Thursday"); dateMap.set(5, "Friday"); dateMap.set(6, "Saturday");

  const leagueMap = new Map();
  leagueMap.set("NHL", "hockeyIcon.png"); leagueMap.set("MLB", "baseball-light.png");
  leagueMap.set("NBA", "basketball-light.png"); leagueMap.set("NFL", "football-light.png");
  leagueMap.set("NCAAB", "basketball-orange.png"); leagueMap.set("CFB", "football-brown.png");  

  const [fullArray, setFullArray] = useState([]);

  if (!Array.isArray(games)) single = true;

  const dateAndGameArray = useCallback((sorted) => {
    const bigArray = [];
    const mySet = new Set();
    var dayArray = null;
    if (range?.to === undefined && range?.from === undefined)
      return bigArray;
    if (range?.to === undefined)
    {
      dayArray = getDaysArray(range.from);
    }
    else
    {
      dayArray = getDaysArray(range.from, range.to);
    }
    for (let i = 0; i < dayArray.length; i++)
    {
      var d = new Date(dayArray[i]);
      var date = d.getDate();
      var month = d.getMonth() + 1;
      var year = d.getFullYear();
      var dateStr = month + "/" + date + "/" + year;
      mySet.add(dateStr);
    }
    
    for(let i = 0; i < sorted.length; i++)
    {
      // get date from gameday value and modify it from d-m-y to d/m/y
      let dateVal = sorted[i]['gameday'];
      let index = dateVal.indexOf("-");
      const year = dateVal.substring(0, index);
      const tempString = dateVal.substring(index+1);
      const indexMonth = tempString.indexOf("-");
      const day = tempString.substring(0, indexMonth);
      const month = dateVal.substring(index+indexMonth+2);
      let date = day + "/" + month + "/" + year;
      let finalDate = date.replace(/(^|[/])0+/g, "$1");
      if (mySet.has(finalDate))
      {
        bigArray.push(
          finalDate
        )
        mySet.delete(finalDate);
      }
      bigArray.push(sorted[i]);
    }

    return bigArray;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games]);

  useEffect(() => {
    var sorted = games;
    if (Array.isArray(games))
    {
      sorted = games.sort((a,b) => (new Date(a['gameday']) - new Date(b['gameday'])));
      const array = dateAndGameArray(sorted);
      setFullArray(array);
    }
    else
    {
      let arr = [];
      arr.push(games);
      const res = dateAndGameArray(arr);
      setFullArray(res);
    }
  }, [dateAndGameArray, games]);

  var getDaysArray = function(start, end) {
    var singleArr = []
    if (end === undefined)
    {
      singleArr.push(new Date(start))
      return singleArr;
    }

    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
    }

    return arr;
  };

  const getTime = (date) => {
    date = date.replace(":00 ", " ");
    date = date.replace(/^0+/, "");

    return date;
  }

  const dateDisplayer = (date) => {
    const d = new Date(date).getDay();
    let dayWritten = dateMap.get(d);
    let ans = dayWritten + ", " + date;

    return ans;
  }

  const styleOptionsGamesInfoList = {
    flexWrap: 'nowrap', display: 'inline-flex', 
    flexDirection: 'row', alignItems: 'center', textAlign: 'center', 
    backgroundColor: '#6a8483', color: 'white',
    borderTop: 'solid', borderWidth: '.1em'
  }

  return (
    <div>
      {fullArray.length !== 0 ? (
        fullArray.map((game) => {
        if (game.home_team_full === undefined) {
          return (
            <Grid container
              sx={{
                flexWrap: 'nowrap', display: 'inline-flex',
                alignItems: 'center', textAlign: 'center',
                backgroundColor: '#6a8483', color: 'white',
                marginTop: '5px', borderRadius: '10px 10px 0px 0px',
                height: '40px'
              }}
            >
              <Grid item xs={12}>
                <h2 className="h2TextSmaller">
                  {dateDisplayer(game)}
                </h2>
              </Grid>
            </Grid>
          )
        }

        return (
          <Grid container className="grey-background" sx={ styleOptionsGamesInfoList }>
            <Grid item xs={8} sx={{ flexWrap: 'nowrap', display: 'inline-flex', 
              flexDirection: 'column', alignItems: 'center', textAlign: 'center', 
              backgroundColor: '#6a8483', color: 'white'}}
            >
              <Grid container sx={{alignSelf: 'flex-start', flexWrap: 'nowrap'}}>
                {/* <Grid item xs={2} sx={{alignSelf: 'center'}}>
                  <img alt="teamTwo" style={{ width: 30, height: 30 }} src={game.away_pic}></img>
                </Grid> */}
                <Grid item xs={2} sx={{alignSelf: 'center'}}>
                  {/* <img alt="teamTwo" style={{ width: 30, height: 30 }} src={'/footballIcon.svg'}></img> */}
                  <img alt="league" style={{ width: 25, height: 25 }} src={'/' + leagueMap.get(game.league)}></img>
                </Grid>
                <Grid item xs={10} sx={{textAlign: 'left'}}>
                  <Grid container>
                    <Grid item>
                      <h2 style={{ paddingLeft: '8px' }} className="h2TextSmaller">{game.away_team_full}</h2>
                    </Grid>
                    <Grid item>
                      <h2 style={{ paddingLeft: '8px' }} className="h2TextSmaller">{game.home_team_full}</h2>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid container sx={{alignSelf: 'flex-start'}}> */}
                {/* <Grid item xs={2} sx={{alignSelf: 'center'}}>
                  <img alt="teamOne" style={{ width: 30, height: 30 }} src={game.home_pic}></img>
                </Grid> */}
                {/* <Grid item xs={12} sx={{textAlign: 'left'}}>
                  <h2 style={{ paddingLeft: '5px' }} className="h2TextSmaller">{game.team}</h2>
                </Grid> */}
              {/* </Grid> */}
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{backgroundColor: 'white', margin: '12px 0 12px 0'}} />
            <Grid item xs={4}>
              <Grid id='head-container' container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item xs={6} sx={{ paddingBottom: '5px', width: '100%', alignSelf: 'center', maxWidth: '100% !important' }}>
                  <div className="bold-date">
                    {(game['gameTime'] !== '') ? 
                    getTime(game['gameTime']) : 'Time: TBD'}
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ flexWrap: 'nowrap', alignContent: 'center', alignSelf: 'center', maxWidth: '80% !important' }}>
                  <Grid container sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Grid item xs={6}>
                      {game['miles']} mi
                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        <a href={'https://vivid-seats.pxf.io/c/5600751/1115710/12730?u=https://www.vividseats.com/search?searchTerm=' + game.home_team_full} target="_top" id="1115710">
                          <img src="//a.impactradius-go.com/display-ad/12730-1115710" border="0" alt="" width="30" height="25.9"/>
                        </a>
                        <img alt='vivid-seats-small-icon' height="0" width="0" src="https://imp.pxf.io/i/5600751/1115710/12730" style={{position:'absolute', visibility:'hidden'}} border="0" />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
        }))
      : null}
    </div>
  )
};

export default GameInfoListForm;
