import Button from '@mui/material/Button';
import { DateDrawer } from './DateDrawer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React, { useState } from "react";
import { TextField } from '@mui/material';
import { Grid } from "@mui/material";


export const Calendar = ({ setRange, range }) => {

  const [calendarDrawerState, setCalendarDrawerState] = useState({top: false});

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }

    setCalendarDrawerState({ ...calendarDrawerState, [anchor]: open });
  };

  // let dateOne = range?.from !== undefined ? new Date(range?.from).toLocaleDateString() : '';
  // let dateOne = range?.from !== undefined ? new Date(range?.from).toLocaleDateString() : '';
  let dateOneDayOfWeek = range?.from !== undefined ? dayNames.at(new Date(range?.from).getDay()) : '';
  let dateOneMonth = range?.from !== undefined ? months.at(new Date(range?.from).getMonth()) : '';
  let dateOneDay = range?.from !== undefined ? new Date(range?.from).getDate() : '';
  let dateOne = range?.from !== undefined ? dateOneDayOfWeek + ', ' + dateOneMonth + ' ' + dateOneDay : '';
  // let dateTwo = range?.to !== undefined ? new Date(range?.to).toLocaleDateString() : '';
  let dateTwoDayOfWeek = range?.to !== undefined ? dayNames.at(new Date(range?.to).getDay()) : '';
  let dateTwoMonth = range?.to !== undefined ? months.at(new Date(range?.to).getMonth()) : '';
  let dateTwoDay = range?.to !== undefined ? new Date(range?.to).getDate() : '';
  let dateTwo = range?.to !== undefined ? dateTwoDayOfWeek + ', ' + dateTwoMonth + ' ' + dateTwoDay : '';

  return (
    <>
      <Button
        disableElevation
        disableFocusRipple
        sx={{ backgroundColor: '#425b62', textAlignLast: 'center',
          color: '#425b62',
          '&:hover': {
          backgroundColor: '#425b62',
          color: '#3c52b2',
          },
          height: '42px'
        }}
        onClick={toggleDrawer('top', true)}
      >
        <Grid container sx={{ alignItems: 'center', textAlign: '-webkit-center' }}>
          <Grid item xs={1} sx={{ alignSelf: 'flex-end' }}>
            <div>
              <CalendarMonthIcon sx={{ color: 'white', alignSelf: 'flex-end' }}/>
            </div>
          </Grid>
          <Grid item xs={5} >
            <TextField label="" color="secondary" type="text"
              defaultValue={dateOne}
              value={dateOne}
              sx={{"& fieldset": { border: 'none' }}}
              id="location"
              InputProps={{
                readOnly: true,
              }}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <div style={{ color: 'white', fontSize: 'x-large' }}>
              -
            </div>
          </Grid>
          <Grid item xs={5}>
            <TextField label="" color="secondary" type="text"
              defaultValue={dateTwo}
              value={dateTwo}
              sx={{"& fieldset": { border: 'none' }}}
              id="location"
              InputProps={{
                readOnly: true,
              }}
              required
            />
          </Grid>
        </Grid>
      </Button>
      {calendarDrawerState.top === true ? (
        <DateDrawer toggleDrawer={toggleDrawer} setRange={setRange} range={range} calendarDrawerState={calendarDrawerState} />
      ) : null}
    </>
  );
}