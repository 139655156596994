import React from "react";
import "./App.css";
//import TripPlanner from '../TripPlanner.js';
import MapContainerComponent from "../TripPlanner/MapContainerComponent.js";
import { Routes, Route } from "react-router-dom";
// import AwaySchedule from "../awayGames/AwaySchedule";

function App() {
  return (
    <div className="App" style={{ textAlign: '-webkit-center' }}>
          <Routes>
            <Route path="/" element={<MapContainerComponent />} />
            {/* <Route path="awayGames" element={<AwaySchedule />} /> */}
          </Routes>
    </div>
  );
}
export default App;
