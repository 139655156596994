import { Drawer, Button } from "@mui/material";
import GameInfoListForm from "./GameInfoListForm.js";
// import { useState } from "react";


export const GameList = ({ stateListDrawer, gamesLocation, toggleListDrawer, location, range, closeButtonLocation }) => {

  // const [stateListDrawer, setStateListDrawer] = useState({top: false});
  // const [gamesLocation, setGamesLocation] = useState();

  return (
    <>
      {stateListDrawer.top === true ? (
        <div>
          <Drawer
            anchor={'top'}
            open={stateListDrawer['top']}
            onClose={toggleListDrawer('top', false)}
            style={{justifyContent: 'center'}}
            className='game-list-drawer'
          >
            <div
              style={{ 
                textAlign: 'center',
                width: '100%',
                backgroundColor: '#6a8483'
              }}
            >
              <a
                href="https://vivid-seats.pxf.io/c/5600751/1983156/12730"
                target="_top"
                id="1983156"
              >
                <img
                  className="vivid-seats-ticket-link"
                  src="//a.impactradius-go.com/display-ad/12730-1983156"
                  border="0" alt="" width="728" height="90"
                />
              </a>
              <img alt="" height="0" width="0" src="https://imp.pxf.io/i/5600751/1983156/12730" style={{position: 'absolute', visibility: 'hidden'}} border="0" />
            </div>
            <GameInfoListForm range={range} games={gamesLocation ? gamesLocation : location} />
            <Button
              className={location.length > 5 ? 'button-bottom' : null}
              onClick={toggleListDrawer('top', false)}
              sx={{
                backgroundColor:'#425b62',
                position: closeButtonLocation,
                borderRadius: 0
              }}
            >
              Close
            </Button>
          </Drawer>
        </div>
      ) : null}
    </>
  );
}