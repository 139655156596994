import { Button } from "@mui/material";
import { List } from 'lucide-react';

export const ListButton = ({ toggleListDrawer }) => {

  return (
    <Button
      variant="contained"
      onClick={toggleListDrawer('top', true)}
      className='list-button'
      sx={{
        color: 'white',
        backgroundColor: '#61dafb',
        bottom: '5%',
        position: 'absolute',
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        left: '51%'
      }}
    >
      <List />
      <div>
        List
      </div>
    </Button>
  );
}