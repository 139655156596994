import TextField from '@mui/material/TextField';
import axios from "axios";
import { Grid, Autocomplete, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';

export const SearchBar = ({ setDefaultCenter, setSelectedPosition, setLat, setLng }) => {
  let baseUrl = global.config.address;

  const [cities, setCities] = useState([]);
  const [city, setCity] = useState();

  useEffect(() => {
    fetch('cities.json').then((res)=>res.json()).then((data)=>{
      setCities(data)
     })
  }, []);

  useEffect(() => {
    if (city == null)
      return;

    if (city?.latitude) {
      console.log(city);
      setSelectedPosition([city.latitude, city.longitude]);
      setDefaultCenter(city.latitude, city.longitude);
      setLat(city.latitude);
      setLng(city.longitude);
      localStorage.setItem("center", JSON.stringify({lat: city.latitude, lng: city.longitude}));
    }
    else {
      axios
      .post(baseUrl + `/locationToGPS/`, {
        search: city.label,
      })
      .then((response) => {
        setSelectedPosition([response.data.lat, response.data.lng]);
        setDefaultCenter(response.data.lat, response.data.lng);
        setLat(response.data.lat);
        setLng(response.data.lng);
        localStorage.setItem("center", JSON.stringify({lat: response.data.lat, lng: response.data.lng}));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          id="location"
          freeSolo
          options={cities}
          getOptionLabel={(option) => option?.label || city?.label || ''}
          value={city?.label || city?.newValue || ''}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
                setCity({label: newValue});
            } else {
              setCity(newValue);
            }
          }}
          sx={{ padding: '0px',
            "& .MuiOutlinedInput-root" : {
              backgroundColor: '#425b62',
              padding: '0px'
            },
            "& .MuiAutocomplete-clearIndicator" : {
              color: 'white'
            },
          }}
          PaperComponent={({ children }) => (
            <Paper style={{ background: "#425b62" }}><div style={{ color:'white' }}>{children}</div></Paper>
          )}
          renderInput={(params) => 
            <TextField
              {...params}
              label={
                <Grid container>
                  <Grid item xs={1}>
                    <SearchIcon sx={{ verticalAlign: 'top' }} />
                  </Grid>
                  <Grid item xs={6}>
                     Enter Location
                  </Grid>
                </Grid>
              }
              InputLabelProps={{
                style: { 
                  color: 'white', top: '-8px', width: '100%' ,
                  "& .MuiOutlinedInput-root" : {
                    backgroundColor: '#425b62',
                    padding: '0px'
                  },
                }
              }}
            />
          }
        />
      </Grid>
    </Grid>
  );
}