import React from 'react';
import { DayPicker } from 'react-day-picker';


const DatePicker = ({ range, setRange }) => {

  let footer = <p>Please pick the first day.</p>;
  if (range?.from) {
    const dateOne = new Date(range.from);
    const ans1 = dateOne.toLocaleDateString(undefined, {
      month:'short',
      day: '2-digit',
      year: 'numeric'
    })
    if (!range.to) {
      footer = <p>{ans1}</p>;
    } else if (range.to) {
      const dateTwo = new Date(range.to);
      const ans2 = dateTwo.toLocaleDateString(undefined, {
        month:'short',
        day: '2-digit',
        year: 'numeric'
      })

      footer = (
        <p>
          {/* {format(range.from, 'PPP')}–{format(range.to, 'PPP')} */}
          {/* {format(range.from, 'mPP')}–{format(range.to, 'mPP')} */}
          {`${ans1} - ${ans2}`}
        </p>
      );
    }
  }

  return (
    <DayPicker
      mode="range"
      selected={range}
      max={6}
      onSelect={setRange}
      footer={footer}
      style={{backgroundColor:'#425b62', margin: '0px', color: '#61dafb',
        display: 'flex', justifyContent: 'center', textAlign: 'center'
      }}
      modifiersStyles={{
        selected: { backgroundColor: '#61dafb' }
      }}
      fromYear={new Date().getFullYear()}
      toYear={new Date().getFullYear()+1}
      defaultMonth={range?.from || new Date()}
    />
  );
};

export default DatePicker;